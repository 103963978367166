<template>
  <v-text-field
    v-model="value"
    :type="type"
    :name="name"
    :label="label"
    :error="error"
    :loading="isLoading"
    :error-messages="message"
    :prepend-icon="icon"
    :append-icon="append_icon"
    :disabled="readonly"
    :readonly="readonlyText"
    :min="min"
    @change="removeError"
    autocomplete="off"
    v-mask="mask_"
    v-show="show"
  >
    <template v-slot:label>
      <span v-if="!optional" class="red--text">*</span> {{ label }}
    </template>
    <template v-slot:append v-if="generated && !readonly">
      <v-btn depressed tile color="primary" class="ma-0" @click="generateString">
        <v-icon right dark style="margin-right: 8px">mdi-reload</v-icon>
      </v-btn>
    </template>
  </v-text-field>
</template>

<script>
const timeMask = (value) => {
  const hours = [/[0-2]/, value.charAt(0) === "2" ? /[0-3]/ : /[0-9]/];
  const minutes = [/[0-5]/, /[0-9]/];
  return value.length > 2 ? [...hours, ":", ...minutes] : hours;
};

export default {
  props: {
    type: String,
    name: String,
    label: String,
    default: [String, Number],
    readonly: Boolean,
    min: Number,
    append_icon: String,
    onChange: Function,
    onLoad: Function,
    forceChange: Boolean,
    decimals: Boolean,
    optional: Boolean,
    delay: Number,
    mask: String,
    time: Boolean,
    generated: Boolean,
    visibility: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.value = this.default;
    if (this.generated) {
      this.readonlyText = true;
    }
  },
  methods: {
    getItem() {
      if (
        this.value === undefined
        || this.value === ""
        || this.type === "text"
        || this.type === "password"
      ) {
        return { name: this.name, value: this.value || undefined };
      }
      return {
        name: this.name,
        value: this.decimals ? parseFloat(this.value) : parseInt(this.value),
      };
    },
    removeError() {
      this.error = false;
      this.message = null;
    },
    setError(message) {
      this.error = true;
      this.message = message;
    },
    setVisibility(val) {
      this.show = val || false;
    },
    loading(val = false) {
      this.isLoading = val;
    },
    generateString() {
      const randomChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      for (let i = 0; i < 32; i++) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
      }
      this.value = result;
    },
  },
  data() {
    return {
      value: null,
      message: null,
      error: false,
      icon: this.type === "text" ? "mdi-format-text" : "mdi-numeric",
      show: this.visibility,
      firstLoad: true,
      isLoading: false,
      writing: null,
      mask_: this.time ? timeMask : this.mask,
      readonlyText: false,
    };
  },
  watch: {
    value(val) {
      if (!this.firstLoad || this.forceChange) {
        if (this.delay) {
          clearTimeout(this.writing);
          this.writing = setTimeout(() => {
            this.$parent.$parent.onChange(this.name, val);
          }, this.delay);
        } else {
          this.$parent.$parent.onChange(this.name, val);
        }
      } else {
        this.firstLoad = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-text-field {
  padding-top: 6px !important;
  margin-top: 4px !important;
}
</style>
