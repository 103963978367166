<template>
  <div>
    <template v-for="(action, index) in actions || []">
      <v-list-item-action v-if="action.condition ? action.condition(item) : true" :key="index">
        <template>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-badge
                bordered
                :color="action.badge_color || action.color"
                overlap
                :content="typeof action.badge == 'function' ? action.badge(item) : action"
                left
                :value="
                  typeof action.badge == 'function' && action.badge(item) !== undefined
                    ? true
                    : false
                "
              >
                <v-btn
                  v-on="on"
                  tile
                  @click="exec(action, item, index)"
                  :color="action.color"
                  :class="action.text_color || 'white' + '--text'"
                >
                  <v-icon
                    v-if="action.icon"
                    color="white"
                    v-html="action.icon"
                    :left="!action.title === false"
                  />
                  {{ action.title }}
                </v-btn>
              </v-badge>
            </template>
            <span>{{ action.help || "" }}</span>
          </v-tooltip>
        </template>
      </v-list-item-action>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    item: Object,
    actions: Array,
  },
  data() {
    return {};
  },
  methods: {
    exec(action, item, index) {
      action.action(item, index);
    },
  },
  computed: {
    hideActions() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        default:
          return false;
      }
    },
  },
};
</script>
<style type="text/css" scoped>
.v-chip.v-size--default {
  border-radius: 16px;
  font-size: 12px;
  height: unset !important;
}
.v-list-item__action {
  margin-right: unset !important;
}
.v-chip {
  margin-right: 5px;
}
.v-list-item__subtitle {
  margin-bottom: 3px;
  user-select: text;
  white-space: unset !important;
}
.v-list-item__title {
  user-select: text;
}
.v-list-item__subtitle .v-chip {
  user-select: none;
}
.v-input__slot {
  margin-bottom: unset !important;
}
.v-text-field--solo {
  border-radius: unset;
  margin-bottom: unset !important;
  box-shadow: 0 1px 1px -2px rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 1px 0 rgba(0, 0, 0, 0.12) !important;
}
</style>
