<template>
  <div v-show="show" style="margin-bottom: 20px;">
    <div>{{label}}</div>
    <v-btn-toggle
      v-model="value"
      borderless
      multiple
      style="width: 100%;"
      active-class="secondary white--text"
    >
      <v-btn
        :value="item[item_value]"
        v-for="item in entries"
        :key="item[item_value]"
        style="border-left: solid 1px; border-right: solid 1px;"
        block
      >
        <span class="hidden-sm-and-down">{{item[item_text]}}</span>
      </v-btn>
    </v-btn-toggle>
  </div>
</template>

<script>
import _ from 'underscore'

export default {
  props: {
    type: String,
    name: String,
    label: String,
    default: {
      type: Array,
      default: () => []
    },
    readonly: Boolean,
    optional: Boolean,
    onChange: Function,
    onLoad: Function,
    forceChange: Boolean,
    options: Array,
    visibility: {
      type: Boolean,
      default: true
    },
    item_text: {
      type: String,
      default: 'name'
    },
    item_value: {
      type: String,
      default: 'id'
    },
  },
  beforeMount() {
    this.value = this.default
    if (this.options) {
      this.entries = this.options
    }
  },
  data() {
    return {
      value: null,
      message: null,
      error: false,
      icon: 'mdi-image-outline',
      show: this.visibility,
      firstLoad: true
    }
  },
  methods: {
    getItem() {
      return { name: this.name, value: this.value }
    },
    removeError() {
      this.error = false
      this.message = null
    },
    setError(message) {
      this.error = true
      this.message = message
    },
    setVisibility(val) {
      this.show = val || false
    },
    getOption() {
      if (this.value === undefined) {
        return undefined
      }
      return _.findWhere(this.entries, { [this.item_value]: this.value })
    },
    getOptions() {
      return this.entries
    },
    setOptions(options) {
      this.entries = options
    },
  },
  computed: {
    items() {
      return (this.entries || []).map((entry) => ({
        ...entry,
        item_text: entry[this.item_text],
        item_value: entry[this.item_text]
      }))
    },
  },
  watch: {
    value(val) {
      if (!this.firstLoad || this.forceChange) {
        this.$parent.$parent.onChange(this.name, val)
      } else {
        this.firstLoad = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .boolean-input-label {
    margin-left: 32px;
    // margin-bottom: 2px;
    font-size: 12px;
    font-weight: 500;
  }

  .grp {
    display: flex;
    margin-bottom: 25px;
  }

  .boolean-input-icon {
    margin-right: 9px;
    .center {
      margin: 0;
      position: relative;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }

  .yes-no {
    height: 50px;
    flex: 1;
    border-bottom: thin solid rgba(0,0,0,.12);
    .selected {
      border-bottom: 2px #0288D1 solid !important;
    }
    .selected-disabled {
      border-bottom: 2px gray solid !important;
    }
    .first {
      float: left;
      width: 50%;
      height: 100%;
    }
    .second {
      float: left;
      width: 50%;
      height: 100%;
    }
    .icon-space {
      font-size: 1rem;
      font-weight: 500;
      text-align: center;
      margin: 0;
      position: relative;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
</style>
