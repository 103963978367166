<template>
  <div class="map-size">
    <input ref="placeAutocomplete">
    <div ref="mapLocation" class="map-size"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Location',
  props: {
    position: String,
    type: {
      default: 'places',
      type: String
    },
    readonly: Boolean
  },
  data() {
    return {
      autocomplete: null,
      map: null,
      zoom: 13,
      marker: null
    };
  },
  mounted() {
    this.map = new window.google.maps.Map(this.$refs.mapLocation, {
      zoom: this.zoom,
      center: { lat: this.getUser.latitude, lng: this.getUser.longitude }
    })
    this.map.addListener('click', (event) => {
      this.setMarker({
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      })
    })
    if (this.type === 'places') {
      this.autocomplete = new window.google.maps.places.Autocomplete(
        this.$refs.placeAutocomplete,
        {
          componentRestrictions: { country: 'mx' },
          fields: ['address_components', 'geometry', 'icon', 'name'],
          strictBounds: false,
        }
      );
      this.autocomplete.addListener('place_changed', () => {
        this.setPlace(this.autocomplete.getPlace())
      })
      if (this.position) {
        let position = this.position.split(',')
        position = { lat: parseFloat(position[0]), lng: parseFloat(position[1]) }
        this.setCenter(position)
        this.map.setZoom(16)
        this.setMarker(position)
      }
    } else if (this.type === 'geocode') {
      const geocoder = new window.google.maps.Geocoder();
      geocoder
        .geocode(this.position)
        .then((result) => {
          const { results } = result;

          this.setCenter(results[0].geometry.location);
          this.setMarker(results[0].geometry.location)
          // marker.setPosition(results[0].geometry.location);
          // marker.setMap(this.map);
          // responseDiv.style.display = "block";
          // response.innerText = JSON.stringify(result, null, 2);
          // return results;
        })
        .catch((e) => {
          console.error(e);
        });
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser'])
  },
  methods: {
    setPlace(place) {
      const placePosition = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      }
      this.setCenter(placePosition)
      if (this.readonly) {
        return
      }
      this.setMarker(placePosition)
    },
    setMarker(position) {
      if (this.readonly) {
        return
      }
      if (!this.marker) {
        this.marker = new window.google.maps.Marker({
          map: this.map
        });
      }
      this.marker.setPosition(position)
    },
    setCenter(center) {
      this.map.setCenter(center)
    },
    getItem() {
      return `${this.marker.getPosition().lat()}, ${this.marker.getPosition().lng()}`
    },
    hasErrors() {
      if (!this.marker) {
        window.VMA.$emit('SHOW_SNACKBAR', {
          text: 'Debes seleccionar una ubicación',
          color: 'error'
        })
        return true
      }
      return false
    }
  }
};
</script>
<style scoped>
  .pac-target-input {
    height: 50px !important;
    width: 100%;
    font-size: 16px;
    padding: 10px;
  }
  .map-size {
    width: 100%;
    height: 780px;
  }
</style>
