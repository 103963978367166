import axios from 'axios';
import store from '@/store'

const ApiService = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 50000,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json'
  }
});

const err = (error) => {
  console.log(error.response)
  const { status } = error.response;
  switch (status) {
    case 401:
      window.VMA.$emit('AUTH_FAIELD');
      break;
    case 403:
      window.VMA.$emit('ACESS_DENIED');
      break;
    case 500:
      window.VMA.$emit('SERVER_ERROR');
      break;
    default:
      break;
  }
  return Promise.reject(error);
};

// request interceptor
ApiService.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${store.getters['auth/getAccessToken']}`;
  return config;
}, err);

// response interceptor
ApiService.interceptors.response.use(({ data }) => data, err);

export default ApiService;
