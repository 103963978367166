<template>
  <v-autocomplete
    v-model="value"
    v-show="show"
    :items="items"
    :loading="isLoading"
    :search-input.sync="search_text"
    hide-no-data
    :item-text="item_text"
    :item-value="item_value"
    :label="label"
    :disabled="readonly"
    :readonly="isLoading && firstLoad"
    :prepend-icon="icon"
    autocomplete="off"
    :error="error"
    :error-messages="message"
    :clearable="true"
    :multiple="multiple"
    @keyup.esc="clear"
  >
    <template v-slot:label>
        <span v-if="!optional" class="red--text">*</span> {{ label }}
    </template>
  </v-autocomplete>
</template>

<script>
import _ from 'underscore'
import ApiService from '@/util/api.service'

export default {
  props: {
    type: String,
    name: String,
    label: String,
    default: [String, Number, Array],
    readonly: Boolean,
    optional: Boolean,
    options: Array,
    onChange: Function,
    onLoad: Function,
    forceChange: Boolean,
    multiple: Boolean,
    query: Object,
    module: String,
    onOptionsLoaded: Function,
    filter: Function,
    autocomplete: {
      type: Boolean,
      default: true
    },
    url: String,
    model: String,
    item_text: {
      type: String,
      default: 'name'
    },
    item_value: {
      type: String,
      default: 'id'
    },
    visibility: {
      type: Boolean,
      default: true
    },
    isForm: {
      default: true
    },
  },
  beforeMount() {
    this.value = this.default
    if (this.options) {
      this.entries = this.options
      this.icon = 'mdi-view-list'
      this.isSelect = true
      this.isLoading = false
      return
    }
    if (this.visibility || this.value) {
      this.load()
    } else {
      this.isLoading = false;
    }
  },
  data() {
    return {
      value: null,
      isSelect: false,
      descriptionLimit: 60,
      entries: [],
      isLoading: true,
      search_text: null,
      message: null,
      error: false,
      show: this.visibility,
      icon: 'mdi-database-search',
      firstLoad: true,
      searching: null,
      filter_: this.filter
    }
  },
  methods: {
    clear() {
      this.value = undefined
    },
    load() {
      if ((this.value && this.autocomplete) || (this.value && this.readonly)) {
        return this.search(null, { [this.item_value]: this.value })
      }
      if (!this.autocomplete && !this.readonly) {
        return this.search(null, {})
      }
      this.isLoading = false
      return []
    },
    setValue(val) {
      this.value = val
    },
    getItem() {
      return {
        name: this.name,
        value: _.isArray(this.value) && !this.value.length ? undefined : this.value
      }
    },
    removeError() {
      this.error = false
      this.message = null
    },
    setError(message) {
      this.error = true
      this.message = message
    },
    setVisibility(val) {
      this.show = val || false
    },
    getOption() {
      if (this.value === undefined) {
        return undefined
      }
      if (_.isArray(this.value)) {
        return this.value.map((value) => _.findWhere(this.entries, { [this.item_value]: value }))
      }
      return _.findWhere(this.entries, { [this.item_value]: this.value })
    },
    getOptions() {
      return this.entries
    },
    setOptions(options = []) {
      this.entries = options
    },
    setFilter(filter) {
      this.filter_ = filter
    },
    search(val, query) {
      let _query = {}
      if (!query && (val.length < 3 || this.isSelect)) {
        return
      }
      this.isLoading = true
      _query = { ...this.query || {}, ...query || {} }
      if (this.module) {
        _query.module = this.module
      }
      ApiService({
        url: this.url || `/${this.model}`,
        method: 'get',
        params: _query,
      }).then((resp) => {
        this.entries = resp
        this.count = resp.length
      }).finally(() => {
        this.isLoading = false
        if (this.onOptionsLoaded && this.isForm) {
          this.onOptionsLoaded.apply(
            this.$parent, [this.value, this.$parent.fields, this.getOption()]
          )
        }
      })
    },
  },
  computed: {
    items() {
      return (this.filter_ ? this.filter_(this.entries) : this.entries).map((entry) => {
        const item = {}
        item[this.item_text] = entry[this.item_text]
          && entry[this.item_text].length > this.descriptionLimit
          ? entry[this.item_text].slice(0, this.descriptionLimit) + '...'
          : entry[this.item_text]
        item[this.item_value] = entry[this.item_value]
        return item
      })
    },
  },
  watch: {
    search_text(val) {
      if (!val || this.isSelect || !this.autocomplete) {
        return
      }
      clearTimeout(this.searching)
      if ((val.length >= 3 || val.length === 0) && !this.firstLoad) {
        this.searching = setTimeout(() => {
          this.search(val)
        }, 500);
      }
    },
    value(val) {
      if (!this.firstLoad && this.isForm) {
        this.$parent.$parent.onChange(this.name, val)
      } else if (!this.isForm) {
        this.setValue(val)
      }
      this.$emit('change', this.getOption())
      this.$emit('update', val)
    },
    // show(val) {
    //   if (val) {
    //     this.load()
    //   }
    // },
    isLoading(val) {
      if (!val && this.firstLoad) {
        this.firstLoad = false
        if (this.forceChange && this.isForm) {
          this.$parent.$parent.onChange(this.name, this.value)
        }
      }
    }
  }
}
</script>
