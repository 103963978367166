<template>
  <div class="form-style" style="max-width: 700px">
    <wrap-node :value="slot_fields" />
  </div>
</template>
<script>
import SimpleSchema from "simpl-schema";
import _ from "underscore";
import TextField from "../inputs/TextField.vue";
// import IconField from '../inputs/IconField.vue'
// import FileInput from '../inputs/FileInput.vue'
import DatePicker from "../inputs/DatePicker.vue";
import BooleanField2 from "../inputs/BooleanField2.vue";
import BooleanField from "../inputs/BooleanField.vue";
import AutocompleteField from "../inputs/AutocompleteField.vue";
import TextArea from "../inputs/TextAreaField.vue";
import AttributesField from "../inputs/AttributesField.vue";
import LocationField from "../inputs/LocationField.vue";
import ArrayField from "../inputs/ArrayField.vue";
import Separator from "../inputs/Separator.vue";
import SelectInline from "../inputs/SelectInline.vue";
// import ColorPicker from '../inputs/ColorPicker.vue'

export default {
  props: {
    schema: Object,
    item: Object,
    readonly: Boolean,
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    AutocompleteField,
    // eslint-disable-next-line vue/no-unused-components
    TextField,
    // // eslint-disable-next-line vue/no-unused-components
    // IconField,
    // // eslint-disable-next-line vue/no-unused-components
    // FileInput,
    // eslint-disable-next-line vue/no-unused-components
    DatePicker,
    // eslint-disable-next-line vue/no-unused-components
    BooleanField,
    // eslint-disable-next-line vue/no-unused-components
    BooleanField2,
    // eslint-disable-next-line vue/no-unused-components
    TextArea,
    // eslint-disable-next-line vue/no-unused-components
    AttributesField,
    // eslint-disable-next-line vue/no-unused-components
    LocationField,
    // eslint-disable-next-line vue/no-unused-components
    ArrayField,
    // eslint-disable-next-line vue/no-unused-components
    Separator,
    // eslint-disable-next-line vue/no-unused-components
    SelectInline,
    "wrap-node": () => import("./WrapNode.vue"),
  },
  data() {
    return {
      obj_schema: this.schema instanceof SimpleSchema ? this.schema.schema() : this.schema,
      simple_schema: null,
      fields: {},
      slot_fields: [],
      readonlyObj: this.readonly ? { readonly: this.readonly } : {},
    };
  },
  created() {
    SimpleSchema.extendOptions([
      "visibility",
      "textarea",
      "location",
      "fields",
      "model",
      "url",
      "autocomplete",
      "attributes",
      "options",
      "item_text",
      "item_value",
      "separator",
      "title",
      "readonly",
      "onChange",
      "default",
      "decimals",
      "changeOnLoad",
      "inline",
      "multiple",
      "delay",
      "module",
      "mask",
      "time",
      "password",
      "generated",
    ]);
    this.simple_schema = this.schema instanceof SimpleSchema ? this.schema
      : new SimpleSchema(this.schema);
    const schema = this.simple_schema.schema();
    this.simple_schema._schemaKeys.forEach((key) => {
      if (key.includes(".$")) {
        return;
      }
      // if(!field.type) {
      //   throw new Meteor.error('Attr "type" is required - key')
      // }
      const field = schema[key];
      const type = this.simple_schema.get(key, "type")[0].type.name;
      let _type = "text";
      let Component = "TextField";
      if (field.password) {
        _type = "password";
      }
      if (field.options || field.model) {
        if (field.inline) {
          Component = "SelectInline";
        } else {
          Component = "AutocompleteField";
          if (field.model && field.model_field) {
            field.item_value = field.model_field;
          }
          if (field.multiple) {
            _type = "Array";
          }
        }
      } else if (field.file) {
        Component = "FileInput";
        if (type === "Array") {
          field.multiple = true;
        }
      } else if (field.attributes) {
        Component = "AttributesField";
      } else if (field.icon) {
        Component = "IconField";
      } else if (field.textarea) {
        Component = "TextArea";
      } else if (field.location) {
        Component = "LocationField";
      } else if (field.color && type === "String") {
        Component = "ColorPicker";
      } else if (field.separator) {
        Component = "Separator";
      } else if (type === "String") {
        Component = "TextField";
      } else if (type === "Number") {
        Component = "TextField";
        _type = "number";
      } else if (type === "Boolean") {
        Component = "BooleanField";
        if (field.changeOnLoad === false) {
          Component = "BooleanField2";
        }
        _type = "boolean";
      } else if (type === "Date") {
        Component = "DatePicker";
      } else if (type === "Array") {
        if (_.isObject(this.simple_schema._schema[`${key}.$`].type.singleType)) {
          Component = "ArrayField";
          _type = "Array";
          field.schema = this.simple_schema._schema[`${key}.$`].type.singleType;
        }
      }
      if (
        field.location
        && this.item
        && Object.prototype.hasOwnProperty.call(this.item, field.fields[0])
        && Object.prototype.hasOwnProperty.call(this.item, field.fields[1])
      ) {
        field.default = `${this.item[field.fields[0]]}, ${this.item[field.fields[1]]}`;
      } else if (this.item && Object.prototype.hasOwnProperty.call(this.item, key)) {
        field.default = this.item[key];
      }
      if (field.defaultValue) {
        field.default = field.defaultValue;
      }
      if (typeof field.readonly === "function") {
        field.readonly = field.readonly(this.item);
      }
      if (typeof field.visibility === "function") {
        field.visibility = field.visibility(this.item);
      }
      const element = this.$createElement(Component, {
        props: {
          ...field,
          type: _type,
          name: key,
          ...this.readonlyObj,
        },
      });
      this.slot_fields.push(element);
    });
  },
  async mounted() {
    function sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }
    /* eslint-disable no-await-in-loop */
    while (!this.$children || !this.$children[0] || !this.$children[0].$children) {
      await sleep(100);
    }
    this.$children[0].$children.forEach((field) => {
      this.fields[field.name] = field;
    });
    _.each(this.fields, (field) => {
      if (field.onLoad) {
        field.onLoad.apply(this, [field.value, this.fields, this.item]);
      }
      if (this.obj_schema[field.name].changeOnLoad !== false) {
        this.onChange(field.name, field.value);
      }
    });
  },
  methods: {
    clean() {
      _.each(this.fields, (field) => {
        field.value = undefined;
      });
    },
    onChange(field, val) {
      if (this.obj_schema[field].onChange) {
        this.obj_schema[field].onChange.apply(this, [val, this.fields, this.item]);
      }
    },
    onLoad(field, val) {
      if (this.obj_schema[field].onLoad) {
        this.obj_schema[field].onLoad.apply(this, [val, this.fields]);
      }
    },
    getFields() {
      return _.toArray(this.fields).map((field) => field.name);
    },
    getItem(validation = false) {
      const obj = { ...this.item } || {};
      const omit = [];
      this.getValidFields().forEach((field) => {
        if (!omit.includes(field.name)) {
          const item = field.getItem(validation);
          if (this.obj_schema[field.name].location && !validation) {
            Object.keys(item).forEach((key) => {
              obj[key] = item[key];
              omit.push(key);
            });
          } else {
            obj[item.name] = item.value;
          }
        }
      });
      return obj;
    },
    getValidFields() {
      return _.toArray(this.fields).filter((field) => !field.separator);
    },
    getValidableFields() {
      return _.toArray(this.fields)
        .filter((field) => field.show && field.type !== "Array")
        .map((field) => field.name);
    },
    hasErrors() {
      const item = _.pick(this.schema, this.getValidableFields());
      const context = new SimpleSchema(item).newContext();
      const error = !context.validate(this.getItem(true));
      const errors = context._validationErrors.filter((err) => err.type !== "keyNotInSchema");
      if (error && errors.length) {
        errors.forEach((key) => {
          this.fields[key.name].setError(context.keyErrorMessage(key.name));
        });
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-style {
  padding: 16px;
}
</style>
